import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bulma-components';
import inputs from '../../lib/inputs';
import FormFromInputs from './FormFromInputs';
import ConditionalContentProcessor from '../../lib/conditionalContentProcessor';
import QueryProcessor from '../../lib/queryProcessor';
import { MAlert } from './Alerts';
import { ReactComponent as Run } from '../../svg/run.svg';
import LongRequest from '../../lib/longRequest';
import QueryDisplay from './QueryDisplay';
import Button from '../lib/Button';
import ButtonGroup from '../lib/ButtonGroup';
import API from '../../lib/api';
import Constants from 'components/Constants';

class TestConditionalContentInputsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null,
      fetchedContent: [],
    };
  }

  componentDidMount() {
    this.props.updateInputValuesAndInitFromInputs(
      this.props.entityType,
      this.props.entityId,
      this.props.inputValues,
      Object.values(this.props.filteredInputsInQueryString || {}),
    );
  }

  componentDidUpdate() {
    if (
      Object.values(this.props.filteredInputsInQueryString || {}).length !==
      Object.values(this.props.inputValues).length
    ) {
      // If the inputValues object hasn't been instantiated we can get to this situation, so we reinit the param values
      this.props.updateInputValuesAndInitFromInputs(
        this.props.entityType,
        this.props.entityId,
        this.props.inputValues,
        Object.values(this.props.filteredInputsInQueryString || {}),
      );
    }
  }

  render() {
    let parameterizedQuery = '';

    const content = this.getConditionalContent();
    if (content) {
      const queryProcessor = new QueryProcessor(
        content.query_obj.query_string,
        this.props.filteredInputsInQueryString,
        this.props.inputValues,
        content.query_obj.data_source,
      );
      parameterizedQuery = queryProcessor.parameterizeQueryString();
    }

    const buttonActive = inputs.areInputsFilledOut(
      this.props.inputValues,
      this.props.filteredInputsInQueryString || {},
    );
    return (
      <form onSubmit={this.onFormSubmit}>
        <FormFromInputs
          inputs={Object.values(this.props.filteredInputsInQueryString || {})}
          inputValues={this.props.inputValues}
          updateInputValues={this.updateInputValues}
          test={true}
          entityId={this.props.entityId}
          entityType={this.props.entityType}
        />
        <Form.Field className="mtm phm">
          <Form.Label>Dynamic Content Name</Form.Label>
          <p>{content ? content.name : 'No content matches condition'}</p>
        </Form.Field>
        {content && (
          <Form.Field className="mtm">
            {content.dynamic_content_type === Constants.DynamicContentTypes.IMAGE ? (
              <Image src={content.query_obj.query_string} />
            ) : (
              <QueryDisplay queryString={parameterizedQuery} />
            )}
          </Form.Field>
        )}
        <ButtonGroup align="right">
          <Button type="button" category="secondary" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button type="submit" status={!buttonActive ? 'disabled' : 'default'}>
            <Run />
            &nbsp;{this.props.submitButtonText}
          </Button>
        </ButtonGroup>
      </form>
    );
  }

  updateInputValues = (updatedInputValues) => {
    this.props.updateInputValuesAndInitFromInputs(
      this.props.entityType,
      this.props.entityId,
      updatedInputValues,
      Object.values(this.props.filteredInputsInQueryString || {}),
    );
  };

  getConditionalContent = () => {
    const conditionalContentProcessor = new ConditionalContentProcessor(
      this.props.conditionalContent,
      this.props.inputValues,
    );

    const contentId = conditionalContentProcessor.getOutputContent();

    return this.props.dynamicContentById?.[contentId];
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.setTestIsLoading(true);
    this.props.setTestResult(null);
    const content = this.getConditionalContent();
    if (!content) {
      MAlert('No Dynamic Content returned', 'Error', 'error');
      this.props.setTestIsLoading(false);
      return;
    }

    const queryObj = content.query_obj;

    if (!queryObj || queryObj.dataSource) {
      MAlert('Unrecognized data source', 'Error', 'error');
      this.props.setTestIsLoading(false);
      return;
    }

    this.props.onCancel();
    const queryProcessor = new QueryProcessor(
      queryObj.query_string,
      this.props.filteredInputsInQueryString,
      this.props.inputValues,
      queryObj.data_source,
    );
    const queryData = {
      data_source: { id: queryObj.data_source?.id },
      dynamic_content_type: content.dynamic_content_type,
      query_string: queryProcessor.parameterizeQueryString(),
    };
    const startTime = Date.now();

    const onResponse = (response, onComplete) => {
      if (response.data.status === 'success') {
        this.setState({
          testIsLoading: false,
        });
        const timeElapsed = Date.now() - startTime;

        const testResult = response.data;
        testResult['timeElapsed'] = timeElapsed;
        this.props.setTestIsLoading(false);
        this.props.setTestResult(testResult);
        onComplete();
      } else if (response.data.status === 'error') {
        this.props.setTestIsLoading(false);
        this.props.setTestResult(response.data);
        onComplete();
      }
    };

    const submitError = (err) => {
      API.defaultError(err);
      this.props.setTestIsLoading(false);
      this.props.setTestResult(null);
    };

    const staticResult = () => {
      const timeElapsed = Date.now() - startTime;
      const resultObj = [[''], [queryProcessor.parameterizeQueryString()]];
      const results = {
        status: 'success',
        result: resultObj,
        dynamicContentType: content.dynamic_content_type,
        timeElapsed: timeElapsed || 0,
      };
      this.props.setTestResult(results);
      this.props.setTestIsLoading(false);
    };

    const longRequest = new LongRequest('/queries');
    queryObj.data_source
      ? longRequest.post(queryData, onResponse, submitError, undefined, undefined, `test/${queryObj.data_source.type}`)
      : staticResult();
  };
}

TestConditionalContentInputsForm.propTypes = {
  dynamicContentById: PropTypes.object,
  filteredInputsInQueryString: PropTypes.object,
  inputValues: PropTypes.object,
  inputsInQueryString: PropTypes.object,
  onCancel: PropTypes.func,
  conditionalContent: PropTypes.object,
  setTestIsLoading: PropTypes.func,
  setTestResult: PropTypes.func,
  updateInputValues: PropTypes.func,
  updateInputValuesAndInitFromInputs: PropTypes.func,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export default TestConditionalContentInputsForm;
