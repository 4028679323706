import Constants from '../components/Constants';
import { toast } from 'react-toastify';
import React from 'react';
import API from './api';
import TagTree from './tagTree';
import { find } from 'lodash';
import initializeLinkedinTrackingScript from './linkedinTrackingScript';
import { scopes as microsoftScopes } from 'components/shared/MicrosoftAuthInterface';
import { v4 as uuidv4 } from 'uuid';

const utils = {
  debounce: (func, wait, immediate) => {
    let timeout;
    return function () {
      const context = this,
        args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  },

  capitalizeString: (str) => {
    if (!str) {
      return '';
    }
    return str[0].toUpperCase() + str.substr(1);
  },

  toTitleCase: (str) => {
    if (!str) {
      return '';
    }
    return str
      .split(' ')
      .map((sub) => sub[0].toUpperCase() + sub.substr(1))
      .join(' ');
  },

  getDynamicContentTags: (template, templateContent, allDynamicContent) => {
    let tagTree = new TagTree();
    if (template?.slides) {
      for (let i = 0; i < template.slides.length; i++) {
        const slide = template.slides[i];
        tagTree = utils._getDynamicContentTagsForSlide(slide, templateContent, i + 1, tagTree, allDynamicContent);
      }
    }
    tagTree = utils._addTemplateConditionsContentTags(tagTree, templateContent);

    return tagTree;
  },

  getDynamicContentTagsForSelectedSlides: (
    currentTemplate,
    templateContent,
    slides,
    selectedSlidesBySlideNum,
    allDynamicContent,
  ) => {
    let tagTree = new TagTree();
    if (slides) {
      for (let i = 0; i < slides.length; i++) {
        if (selectedSlidesBySlideNum[i + 1]) {
          const slide = slides[i];
          tagTree = utils._getDynamicContentTagsForSlide(slide, templateContent, i + 1, tagTree, allDynamicContent);
        }
      }
    }
    // we want 'slideNums' to reference all the matching tags in the template, not just w/i the selected set.
    const allTags = utils.getDynamicContentTags(currentTemplate, allDynamicContent);
    for (const tagName in tagTree.tagNodesByName) {
      if (allTags.tagNodesByName[tagName]) {
        tagTree.tagNodesByName[tagName].slideNums = allTags.tagNodesByName[tagName].slideNums;
      }
    }
    tagTree = utils._addTemplateConditionsContentTags(tagTree, templateContent);
    return tagTree;
  },

  getTagFromTag: (tag) => {
    if (tag.indexOf(':') >= 0) {
      return tag.split(':')[0];
    }

    return tag;
  },

  splitTag: (tag, id = tag) => {
    if (tag.indexOf(':') >= 0) {
      return tag.split(':');
    }

    return [id, tag];
  },

  getDynamicContentFromTag: (tag) => {
    let dynamicContentName = tag;
    if (tag.indexOf(':') >= 0) {
      dynamicContentName = tag.split(':')[1];
    }

    if (dynamicContentName.indexOf('.') >= 0) {
      dynamicContentName = dynamicContentName.split('.')[0];
    }

    if (dynamicContentName.indexOf('|') >= 0) {
      dynamicContentName = dynamicContentName.split('|')[0];
    }

    return dynamicContentName;
  },

  tagFromPartsIncludingName: (contentName, tagName, subcontent, format) => {
    const initialTag = utils.tagFromParts(contentName, subcontent, format);
    if (tagName && tagName !== contentName) {
      return `${tagName}:${initialTag}`;
    }
    return initialTag;
  },

  tagFromParts: (contentName, subcontent, format) => {
    let tag = contentName;
    if (subcontent?.length > 0) {
      tag += `.${subcontent}`;
    }
    if (format && format.length > 0) {
      tag += `|${format}`;
    }

    return tag;
  },

  getSubContentFromTag: (tag) => {
    let subContent = [];
    let tagNoFormat = tag;
    if (tag.indexOf('|') >= 0) {
      tagNoFormat = tag.split('|')[0];
    }
    if (tagNoFormat.indexOf('.') >= 0) {
      subContent = tag.split('.').slice(1).join('.');
    }
    if (subContent.indexOf('|') !== -1) {
      subContent = subContent.split('|')[0];
    }

    return subContent;
  },

  getFormatsFromTag: (tag) => {
    return tag.indexOf('|') >= 0 ? tag.split('|').slice(1) : [];
  },

  getTagParts: (tag) => {
    if (!tag) {
      return ['', '', '', ''];
    }
    const tagName = utils.getTagFromTag(tag);
    const content = utils.getDynamicContentFromTag(tag);
    const subcontent = utils.getSubContentFromTag(tag);
    const formats = utils.getFormatsFromTag(tag);
    return [tagName, content, subcontent, formats];
  },

  _addTemplateConditionsContentTags: (tagTree, templateContent) => {
    const tagNum = [];
    if (templateContent?.template_condition_content) {
      templateContent.template_condition_content.forEach((content) => {
        const tag = content.name;
        const tempTagNum = utils.getTagFromTag(tag);
        if (tagNum.indexOf(tempTagNum) === -1) {
          tagNum.push(tempTagNum);
        }
        const processedTag = utils.getDynamicContentFromTag(tag);
        const matchingContent = content;
        const slideNum = null;
        tagTree.addTag(
          processedTag,
          utils.getSubContentFromTag(tag),
          utils.getFormatsFromTag(tag),
          matchingContent ? utils.capitalizeString(matchingContent.dynamic_content_type) : '-',
          matchingContent,
          slideNum,
          tagNum,
        );
      });
    }
    return tagTree;
  },

  _getDynamicContentTagsForSlide: (slide, templateContent, slideNum = -1, tagTree = null, allDynamicContent = {}) => {
    if (!tagTree) {
      tagTree = new TagTree();
    }
    const slideMatchingContent = {};
    templateContent?.content_by_slide
      ?.filter((content) => content.slide_ids.indexOf(parseInt(slide.id)) > -1)
      ?.forEach((content) => {
        slideMatchingContent[content.name] = content;
      });

    const dynamicContentInTags = {};
    const tagNum = [];

    if (slide.dynamic_content_tags) {
      slide.dynamic_content_tags.forEach((tag) => {
        const tempTagNum = utils.getTagFromTag(tag);
        if (tagNum.indexOf(tempTagNum) === -1) {
          tagNum.push(tempTagNum);
        }
        const processedTag = utils.getDynamicContentFromTag(tag);
        dynamicContentInTags[processedTag] = true;
        let matchingContent = null;
        const conditionalSubContent = [];
        if (slideMatchingContent[processedTag]) {
          matchingContent = slideMatchingContent[processedTag];
          if (matchingContent.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL) {
            if (utils.isValidJSON(matchingContent?.query_obj?.query_string)) {
              const queryStringJSON = JSON.parse(matchingContent?.query_obj?.query_string);
              queryStringJSON.conditions.forEach((condition) => {
                const matchingSubContent = find(allDynamicContent, { id: condition.dynamicContent });
                if (matchingSubContent) {
                  dynamicContentInTags[matchingSubContent.name] = true;
                  conditionalSubContent.push({ name: matchingSubContent.name, matchingContent: matchingSubContent });
                }
              });
            }
          }
        }
        tagTree.addTag(
          processedTag,
          utils.getSubContentFromTag(tag),
          utils.getFormatsFromTag(tag),
          matchingContent ? utils.capitalizeString(matchingContent.dynamic_content_type) : '-',
          matchingContent,
          slideNum,
          tagNum,
        );

        if (conditionalSubContent.length) {
          conditionalSubContent.forEach((subContent) => {
            tagTree.addTag(
              processedTag,
              subContent.name,
              [],
              utils.capitalizeString(subContent.matchingContent.dynamic_content_type),
              subContent.matchingContent,
              slideNum,
            );
          });
        }
      });
    }

    // add virtual tags for nested content
    if (slideMatchingContent) {
      Object.values(slideMatchingContent).forEach((matchingContent) => {
        if (!dynamicContentInTags[matchingContent.name]) {
          tagTree.addTag(
            matchingContent.name,
            [], // subContentName
            [], // formats
            utils.capitalizeString(matchingContent.dynamic_content_type),
            matchingContent,
            slideNum,
          );
        }
      });
    }
    return tagTree;
  },

  getTagFromSingleDynamicContent: (content) => {
    const tagTree = new TagTree();
    const tagNum = utils.getTagFromTag(content.name);
    const processedTag = utils.getDynamicContentFromTag(content.name);
    const matchingContent = content;
    const slideNum = -1;

    tagTree.addTag(
      processedTag,
      utils.getSubContentFromTag(content.name),
      utils.getFormatsFromTag(content.name),
      matchingContent ? utils.capitalizeString(matchingContent.dynamic_content_type) : '-',
      matchingContent,
      slideNum,
      tagNum,
    );

    return tagTree.tagNodesByName[content.name];
  },

  slideHasUnmatchedContentTags: (slide, templateContent) => {
    if (slide.dynamic_content_tags) {
      const slideMatchingContent = {};
      templateContent?.content_by_slide
        ?.filter((content) => content.slide_ids.indexOf(slide.id) > -1)
        ?.forEach((content) => {
          slideMatchingContent[content.name] = content;
        });
      for (let tag of slide.dynamic_content_tags) {
        const processedTag = utils.getDynamicContentFromTag(tag);
        if (!slideMatchingContent[processedTag]) {
          return true;
        }
      }
    }
    return false;
  },

  getDataSourcesForTemplate: (template, templateContent) => {
    const dataSourcesById = {};
    for (let slide of template.slides) {
      const slideMatchingContent = {};
      templateContent?.content_by_slide
        ?.filter((content) => content.slide_ids.indexOf(slide.id) > -1)
        ?.forEach((content) => {
          slideMatchingContent[content.name] = content;
        });
      for (let contentName in slideMatchingContent) {
        const content = slideMatchingContent[contentName];
        if (content.query_obj && content.query_obj.data_source && content.query_obj.data_source.id) {
          dataSourcesById[content.query_obj.data_source.id] = content.query_obj.data_source;
        }
      }
    }

    return dataSourcesById;
  },

  getOAuthRedirectUri: (encode = false) => {
    // eslint-disable-next-line no-undef
    const redirectUri = `${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/oauth_callback/`;
    if (encode) {
      return encodeURIComponent(redirectUri);
    }
    return redirectUri;
  },

  openGoogleOauthPopup: (nonce, onUpdate, urlParams, integration = 'google') => {
    if (!urlParams) {
      urlParams = '';
    }
    const baseUrl = Constants.GOOGLE_AUTH.base_url;
    const redirectUri = utils.getOAuthRedirectUri();
    const scope =
      'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fpresentations%20email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fspreadsheets%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fspreadsheets.readonly';
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // eslint-disable-line no-undef
    const url =
      `${baseUrl}?scope=${scope}&access_type=offline&include_granted_scopes=true&` +
      `redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&state={"nonce": "${nonce}"}::::${integration}&${urlParams}`;
    window.open(url, 'Google OAuth', 'height=800,width=600');
    window.onOauthWindowClose = (data) => {
      onUpdate(data);
    };
  },

  connectGoogle: (user, updateUser, onSuccess, onPermsFailure) => {
    const googleIntegration = utils.googleIntegration(user);
    if (googleIntegration && !googleIntegration.has_necessary_scopes) {
      utils.reconnectGoogle(user, updateUser, onSuccess, onPermsFailure);
    } else if (!googleIntegration) {
      utils.openGoogleOauthPopup(
        user.nonce,
        (newUser) => {
          updateUser(newUser);
          if (
            newUser?.integrations?.find(({ name }) => name === Constants.USER_INTEGRATION_TYPES.google)
              ?.has_necessary_scopes ??
            false
          ) {
            onSuccess();
          } else {
            onPermsFailure();
          }
        },
        'prompt=consent',
      );
    } else {
      onSuccess();
    }
  },

  reconnectGoogle: (user, updateUser, onSuccess, onPermsFailure) => {
    const googleIntegration = utils.googleIntegration(user);
    if (googleIntegration) {
      const integrationId = googleIntegration.id;
      API.delete(
        `/users/me/integrations/${integrationId}/`,
        (response) => {
          const newUser = response.data;
          updateUser(newUser);
          utils.connectGoogle(newUser, updateUser, onSuccess, onPermsFailure);
        },
        API.defaultError,
        null,
      );
    }
  },

  openMicrosoftOauthPopup: (nonce, onUpdate, urlParams = '', integration = 'microsoft') => {
    const baseUrl = Constants.MICROSOFT.auth_base_url;
    const redirectUri = utils.getOAuthRedirectUri();
    const scope = microsoftScopes.join(' ');
    const clientId = process.env.REACT_APP_OFFICE_365_CLIENT_ID; // eslint-disable-line no-undef
    const tenant = 'common';
    const url = `${baseUrl}/${tenant}/oauth2/v2.0/authorize?\
      client_id=${clientId}\
      &response_type=code\
      &redirect_uri=${redirectUri}\
      &response_mode=query
      &scope=${scope}\
      &state={"nonce": "${nonce}"}::::${integration}\
      &include_granted_scopes=true\
      &${urlParams}`;
    window.open(url, 'Microsoft OAuth', 'height=800,width=600');
    window.onOauthWindowClose = (data) => {
      onUpdate(data);
    };
  },

  connectMicrosoft: (user, updateUser, onSuccess, onPermsFailure) => {
    const microsoftIntegration = utils.microsoftIntegration(user);
    if (microsoftIntegration && !microsoftIntegration.has_necessary_scopes) {
      utils.reconnectMicrosoft(user, updateUser, onSuccess, onPermsFailure);
    } else if (!microsoftIntegration) {
      utils.openMicrosoftOauthPopup(
        user.nonce,
        (newUser) => {
          updateUser(newUser);
          if (
            newUser?.integrations?.find(({ name }) => name === Constants.USER_INTEGRATION_TYPES.microsoft)
              ?.has_necessary_scopes ??
            false
          ) {
            onSuccess();
          } else {
            onPermsFailure();
          }
        },
        'prompt=consent',
      );
    } else {
      onSuccess();
    }
  },

  reconnectMicrosoft: (user, updateUser, onSuccess, onPermsFailure) => {
    const microsoftIntegration = utils.microsoftIntegration(user);
    if (microsoftIntegration) {
      const integrationId = microsoftIntegration.id;
      API.delete(
        `/users/me/integrations/${integrationId}/`,
        (response) => {
          const newUser = response.data;
          updateUser(newUser);
          utils.connectMicrosoft(newUser, updateUser, onSuccess, onPermsFailure);
        },
        API.defaultError,
        null,
      );
    }
  },

  openGoogleBQOauthPopup: (nonce, onUpdate, dataSourceName, projectId, urlParams, isUserDataSource = false) => {
    if (!urlParams) {
      urlParams = '';
    }
    const baseUrl = Constants.GOOGLE_AUTH.base_url;
    // eslint-disable-next-line no-undef
    const redirectUrl = `${process.env.REACT_APP_MATIK_BACKEND_SERVICE_URL}/_api/auth/oauth_callback/`;
    const scope = 'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbigquery%20';
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID; // eslint-disable-line no-undef
    const url =
      `${baseUrl}?scope=${scope}&access_type=offline&include_granted_scopes=true&` +
      `redirect_uri=${redirectUrl}&response_type=code&client_id=${clientId}&` +
      `state={"nonce":"${nonce}","name": "${dataSourceName}","database": "${projectId}","is_user_data_source": ${isUserDataSource}}::::googlebq&${urlParams}`;
    window.open(url, 'Google OAuth', 'height=800,width=600');
    window.onOauthWindowClose = (newUser) => {
      onUpdate(newUser);
    };
  },

  openSalesforceOauthPopup: (
    nonce,
    onUpdate,
    dataSourceName,
    urlParams = '',
    isProduction = true,
    isUserDataSource = false,
  ) => {
    const baseUrl = isProduction
      ? 'https://login.salesforce.com/services/oauth2/authorize?response_type=code&display=popup'
      : 'https://test.salesforce.com/services/oauth2/authorize?response_type=code&display=popup';
    const redirectUri = utils.getOAuthRedirectUri(true);
    const scope = 'api%20id%20refresh_token';
    const clientId = process.env.REACT_APP_SALESFORCE_CLIENT_ID; // eslint-disable-line no-undef
    const url =
      `${baseUrl}&scope=${scope}&` +
      `redirect_uri=${redirectUri}&client_id=${clientId}&state={"nonce":"${nonce}","name": "${dataSourceName}","is_production":${isProduction}, "is_user_data_source":${isUserDataSource}}::::salesforce&${urlParams}`;
    window.open(url, 'Salesforce OAuth', 'height=800,width=600');
    window.onOauthWindowClose = (dataSource) => {
      onUpdate(dataSource);
    };
  },

  openHubspotOauthPopup: (nonce, onUpdate, dataSourceName, urlParams = '', isUserDataSource = false) => {
    const baseUrl = 'https://app.hubspot.com/oauth/authorize';
    const redirectUri = utils.getOAuthRedirectUri(true);
    const clientId = process.env.REACT_APP_HUBSPOT_CLIENT_ID; // eslint-disable-line no-undef
    const scope = 'crm.objects.companies.read';
    const optional_scope = encodeURIComponent(
      'crm.lists.read crm.objects.contacts.read crm.objects.marketing_events.read crm.schemas.custom.read crm.objects.custom.read crm.schemas.contacts.read crm.objects.feedback_submissions.read crm.objects.companies.read crm.objects.deals.read crm.schemas.companies.read crm.schemas.deals.read crm.objects.owners.read crm.objects.quotes.read crm.schemas.quotes.read crm.objects.line_items.read crm.schemas.line_items.read tickets',
    );
    const url =
      `${baseUrl}` +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${scope}` +
      `&optional_scope=${optional_scope}` +
      `&state={"nonce":"${nonce}","name": "${dataSourceName}","is_user_data_source": ${isUserDataSource}}::::hubspot&${urlParams}`;
    window.open(url, 'Hubspot OAuth', 'height=800,width=600');
    window.onOauthWindowClose = (dataSource) => {
      onUpdate(dataSource);
    };
  },

  openSnowflakeOAuthPopup: (nonce, onUpdate, dataSourceName, host, database, auth, isUserDataSource = false) => {
    const redirectUri = utils.getOAuthRedirectUri(true);
    const clientId = encodeURIComponent(auth.client_id);
    const loginUrl = `https://${host}.snowflakecomputing.com/oauth/authorize`;
    const tokenUrl = `https://${host}.snowflakecomputing.com/oauth/token-request`;
    const state = {
      nonce: nonce,
      name: dataSourceName,
      is_user_data_source: isUserDataSource,
      host: host,
      i: clientId,
      l: loginUrl,
      t: tokenUrl,
    };
    if (!isUserDataSource) {
      state.database = database;
      state.s = encodeURIComponent(auth.client_secret);
    }
    const url =
      `${loginUrl}?response_type=code` +
      `&client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&state=${JSON.stringify(state)}::::${Constants.DATA_SOURCE_TYPES.snowflake}`;
    window.open(url, 'Snowflake OAuth', 'height=800,width=800');
    window.onOauthWindowClose = (dataSource) => {
      onUpdate(dataSource);
    };
  },

  openRestAPIOAuthPopup: (nonce, onUpdate, dataSourceName, auth, urlParams = '', isUserDataSource = false) => {
    const redirectUri = utils.getOAuthRedirectUri(true);
    let url =
      `${auth.login_url}` +
      `?client_id=${auth.client_id}` +
      `&redirect_uri=${redirectUri}` +
      `&state={"nonce":"${nonce}","name":"${dataSourceName}","is_user_data_source":${isUserDataSource},"s":"${
        auth.client_secret
      }","i":"${auth.client_id}","t":"${auth.token_url}","l":"${auth.login_url}","b":"${auth.base_url}","tu":"${
        auth.test_url || ''
      }","sc":"${auth.scope || ''}","rt":"${auth.response_type || ''}"}::::api&${urlParams}`;
    if (auth.response_type) {
      url += `&response_type=${auth.response_type}`;
    }
    if (auth.scope) {
      url += `&scope=${auth.scope}`;
    }
    window.open(url, `${dataSourceName} Auth`, 'height=800,width=600');
    window.onOauthWindowClose = (dataSource) => {
      onUpdate(dataSource);
    };
  },

  b64ToBlob: (inputData, sliceSize = 512) => {
    const semiPos = inputData.indexOf(';');
    const contentType = inputData.substr(0, semiPos).split(':')[1];
    const b64Data = inputData.split(',')[1];

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  },

  googleIntegration: (user) => {
    if (!user || !user.integrations) {
      return null;
    }
    return find(user.integrations, (integration) => integration.name === Constants.USER_INTEGRATION_TYPES.google);
  },

  microsoftIntegration: (user) => {
    if (!user || !user.integrations) {
      return null;
    }
    return find(user.integrations, (integration) => integration.name === Constants.USER_INTEGRATION_TYPES.microsoft);
  },

  storageAvailable: (type) => {
    let storage = null;
    try {
      storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage.length !== 0
      );
    }
  },
  notify: (notification, type = toast.TYPE.SUCCESS) => {
    toast(notification, { type: type, containerId: 'default' });
  },

  createInputFromInputCollections: (inputName, inputCollections) => {
    let input = {
      name: inputName,
      type: Constants.InputTypes.STRING,
      source_type: Constants.InputSources.USER_INPUT,
      description: '',
      isNew: true,
      loops: [],
    };
    for (let collection of inputCollections) {
      if (collection && collection[inputName]) {
        input = collection[inputName];
        break;
      }
    }

    return input;
  },

  convertArrayToEnglish: (arr) => {
    if (arr.length === 2) {
      return arr[0] + ' and ' + arr[1];
    }

    let commaArr = arr.join(', ');
    const lastCommaIndex = commaArr.lastIndexOf(',');
    if (lastCommaIndex > -1) {
      commaArr = commaArr.substring(0, lastCommaIndex + 1) + ' and' + commaArr.substring(lastCommaIndex + 1);
    }
    return commaArr;
  },

  convertComponentArrayToEnglish: (arr) => {
    const joined = [];
    for (let i = 0; i < arr.length; i++) {
      if (i > 0) {
        if (i === arr.length - 1) {
          joined.push(<span>{arr.length > 2 ? ', ' : ''} and </span>);
        } else {
          joined.push(<span>, </span>);
        }
      }
      joined.push(arr[i]);
    }
    return joined;
  },

  dedupeArray: (arr) => {
    return arr.filter((item, index, self) => self.indexOf(item) === index);
  },

  preventSubmit: (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  },

  modelExploreId: (modelName, modelExploreName) => {
    return `${modelName}:::::${modelExploreName}`;
  },

  tableHeader: (value) => {
    const header = () => (
      <span className="is-flex">
        <span>{value}</span>
        <span className="is-flex sort-arrows">
          <div className="sort-arrow"></div>
        </span>
      </span>
    );

    return header;
  },
  getPaginationFromRequest: (count, limit, offset, sort) => {
    const currentPage = Math.floor(offset / limit);
    const numberOfPages = Math.ceil(count / limit);
    return { currentPage, numberOfPages, limit, totalCount: parseInt(count), sort };
  },
  getItemFromId: (itemId, currentItem, currentItems, baseUrl, onSuccess, onError, onNew) => {
    if (itemId && itemId === 'new') {
      return onNew();
    }

    return utils.getItemFromField('id', parseInt(itemId), currentItem, currentItems, baseUrl, onSuccess, onError);
  },
  getItemFromName: (itemName, currentItem, currentItems, baseUrl, onSuccess, onError) => {
    return utils.getItemFromField('name', itemName, currentItem, currentItems, baseUrl, onSuccess, onError);
  },
  getItemFromField(fieldName, fieldVal, currentItem, currentItems, baseUrl, onSuccess, onError) {
    if (fieldVal) {
      if (currentItem && currentItem[fieldName] === fieldVal) {
        onSuccess(currentItem);
        return;
      }

      let currentItemArr = currentItems.filter((content) => content[fieldName] === fieldVal);
      if (currentItemArr.length > 0) {
        onSuccess(currentItemArr[0]);
      } else {
        API.get(
          `${baseUrl}${encodeURIComponent(fieldVal)}/`,
          (response) => {
            onSuccess(response.data);
          },
          onError,
        );
      }
    } else {
      onSuccess(null);
    }
  },
  convertPxToNum: (numWithPix) => {
    return parseInt(numWithPix.substring(0, numWithPix.length - 2));
  },

  textWidth: (textContent, fontSize, fontFamily, transform) => {
    const text = document.createElement('span');
    document.body.appendChild(text);

    text.style.fontFamily = fontFamily;
    text.style.fontSize = fontSize;
    text.style.height = 'auto';
    text.style.width = 'auto';
    text.style.position = 'absolute';
    text.style.whiteSpace = 'pre';
    text.style.transform = transform;
    text.style.transformOrigin = 'left bottom';
    text.innerHTML = textContent; //eslint-disable-line

    let width = text.clientWidth;
    const height = text.clientHeight;
    for (let chr of textContent) {
      if (chr === ' ') {
        width -= 5;
      }
    }

    document.body.removeChild(text);
    return [width, height];
  },

  isValidJSON: (text) => {
    try {
      JSON.parse(text);
      return true;
    } catch {
      return false;
    }
  },

  safeJSONParse: (text) => {
    let res = '';
    try {
      res = JSON.parse(text);
    } catch {
      // eslint-disable-next-line no-console
      console.info('Could not parse JSON: ', text);
    }

    return res;
  },

  downloadAsEnvFile: (fileContent, exportName) => {
    const dataStr = 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent);
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.env');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  },

  truncateString: (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + '...';
    } else {
      return text;
    }
  },

  /**
   * Strips leading and trailing whitespace from the query, and also replaces
   * sequences of consesecutive whitespace characters with a single space.
   */
  formatWhitespace: (query) => {
    return query.trim().replaceAll(/\s+/g, ' ');
  },

  removeCopiedFontStylesFromWYSIWYGOutput: (outputObj) => {
    if (outputObj.blocks && outputObj.blocks.length > 0) {
      outputObj.blocks.forEach((block) => {
        if (block.inlineStyleRanges) {
          block.inlineStyleRanges = block.inlineStyleRanges.filter((style) => {
            if (
              !style.style.includes('color') &&
              !style.style.includes('fontsize') &&
              !style.style.includes('fontfamily')
            ) {
              return style;
            }
          });
        }
      });
    }
    return outputObj;
  },

  // Thanks to https://stackoverflow.com/questions/15397372/javascript-new-date-ordinal-st-nd-rd-th
  getOrdinalNum: (number) => {
    let selector;

    if (number <= 0) {
      selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
      selector = 0;
    } else {
      selector = number % 10;
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
  },

  isEmailValid: (email) => {
    const emailPattern = new RegExp(
      // prettier-ignore
      "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])", // eslint-disable-line
      'i',
    );
    return email && emailPattern.test(email);
  },

  isURLValid: (url, httpOnly = true) => {
    // Node's implementation of URL validates according to the WHATWG standard: https://nodejs.org/api/url.html#the-whatwg-url-api
    try {
      const parsedUrl = new URL(url);
      return !httpOnly || parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
    } catch (err) {
      return false;
    }
  },

  getZendeskScript: new Promise((resolve) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.defer = true;
    // eslint-disable-next-line scanjs-rules/assign_to_src
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=e57a142a-74dc-4c2a-b48e-85e9d2b7335c';
    resolve(script);
  }),

  getTemplateText: (template, presentation) => {
    let templateText;
    if (template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL && presentation?.is_test_slide) {
      templateText = 'test email';
    } else if (template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
      templateText = 'emails';
    } else {
      templateText = 'presentations';
    }
    return templateText;
  },

  getLinkedinTrackingScript: async () => {
    try {
      await initializeLinkedinTrackingScript(window.lintrk);
      return true;
    } catch (err) {
      return false;
    }
  },

  downloadExcelFile: (e = {}, selectedDataSourceObject) => {
    e?.preventDefault?.();
    API.requestWithDownload(
      'get',
      `/data_sources/${selectedDataSourceObject.id}/download_excel/`,
      'blob',
      (response) => {
        API.track('excel_download');
        const blob = response.data;
        const reader = new FileReader();
        reader.onload = (event) => {
          const a = document.createElement('a');
          a.href = event.target.result; //eslint-disable-line scanjs-rules/assign_to_href
          a.download = `${JSON.parse(selectedDataSourceObject.host)?.name}`;
          a.click();
        };
        reader.readAsDataURL(blob);
      },
    );
  },

  downloadPowerpointFile: (e = {}, presentation, onSuccess = () => {}) => {
    e?.preventDefault?.();
    API.requestWithDownload('get', `/presentations/${presentation.id}/download_365/`, 'blob', (response) => {
      API.track('powerpoint_download');
      const blob = response.data;
      const reader = new FileReader();
      reader.onload = (event) => {
        const a = document.createElement('a');
        a.href = event.target.result; //eslint-disable-line scanjs-rules/assign_to_href
        a.download = presentation.name;
        a.click();
        onSuccess();
      };
      reader.readAsDataURL(blob);
    });
  },

  openExcelFileInNewTab: (e = {}, selectedDataSourceObject) => {
    e?.preventDefault?.();
    API.get(`/data_sources/${selectedDataSourceObject.id}/share_excel/`, (response) => {
      API.track('excel_share');
      const url = response.data?.url;
      if (url) {
        const win = window.open(url, '_blank');
        win.focus();
      }
    });
  },

  openPowerpointFileInNewTab: (e = {}, presentation, onSuccess = () => {}) => {
    e?.preventDefault?.();
    API.get(`/presentations/${presentation.id}/share_365/`, (response) => {
      API.track('powerpoint_share');
      const url = response.data?.url;
      if (url) {
        const win = window.open(url, '_blank');
        win.focus();
        onSuccess();
      }
    });
  },

  openTemplateFileInNewTab: (e = {}, template, onSuccess = () => {}) => {
    e?.preventDefault?.();
    API.get(`/templates/${template.id}/share_365/`, (response) => {
      API.track('powerpoint_template_share');
      const url = response.data?.url;
      if (url) {
        const win = window.open(url, '_blank');
        win.focus();
        onSuccess();
      }
    });
  },

  /**
   *
   * @param {FolderData} folderData
   * @param {Constants.IntegrationFolderTypes} folderType
   * @param {function} onSuccess
   * FolderData structure
   * {
   * folder_id: <id of folder from picker, NOT THE ID from IntegrationFolders model>,
      drive_id: <optional, only needed for microsoft>,
      folder_name: <path to show on FE in viewer>,
      folder_type: <template, user, group, or enterprise>,
      source_type: <google or microsoft>,
      template_id: <ignored if not type template>,
      group_id: <ignored if not type group>,
   * }
   */
  createIntegrationFolder: (folderData = {}, folderType, onSuccess = () => {}) => {
    if (Object.values(Constants.IntegrationFolderTypes).indexOf(folderType) < 0) {
      // return error ?
      return;
    }
    API.post(
      '/integration_folders/',
      { ...folderData, folder_type: folderType },
      (response) => {
        onSuccess(response);
      },
      API.defaultError,
    );
  },

  updateIntegrationFolder: (folderId, folderData = {}, onSuccess = () => {}) => {
    API.put(
      `/integration_folders/${folderId}/`,
      folderData,
      (response) => {
        onSuccess(response);
      },
      API.defaultError,
    );
  },
  /*
   * group_data: [[group_obj][folder_obj]]
   *
   * 1. groupObj: Object containing group metadata.
   *    @property {number} id - The unique identifier for the group.
   *
   * 2. folderObj: Object containing folder details.
   *    @property {number} [id] - The optional unique identifier for the folder.
   *    @property {string} folder_id - The required folder ID.
   *    @property {string} folder_name - The required name of the folder.
   *    @property {('microsoft'|'google')} source_type - The required storage provider type (either Microsoft or Google).
   *    @property {string} [drive_id] - The optional drive ID, if applicable.
   *
   * This endpoint will automatically determine which folders need to get updated/added/deleted and perform the actions.
   */

  handleIntegrationFoldersUpdate: ({ new_group_data, old_group_data }, onSuccess = () => {}) => {
    API.put(
      '/integration_folders/bulk/',
      { new_group_data, old_group_data },
      (response) => {
        onSuccess(response);
      },
      API.defaultError,
    );
  },

  getIntegrationFolder: (folderId, onSuccess = () => {}) => {
    API.get(
      `/integration_folders/${folderId}/`,
      (response) => {
        onSuccess(response);
      },
      API.defaultError,
    );
  },

  deleteIntegrationFolder: (folderId, onSuccess = () => {}) => {
    API.delete(
      `/integration_folders/${folderId}/`,
      (response) => {
        onSuccess(response);
      },
      API.defaultError,
    );
  },

  getIntegrationFolderNameByTemplate: (templateId, onSuccess = () => {}) => {
    API.get(
      `/templates/${templateId}/integration_folder_name/`,
      (response) => {
        onSuccess(response?.data?.integration_folder_name);
      },
      API.defaultError,
    );
  },

  getIntegrationFolderNameByUser: (folderProvider, userId, onSuccess = () => {}) => {
    API.get(
      `/users/${userId}/integration_folder_name/${folderProvider}`,
      (response) => {
        onSuccess(response?.data?.integration_folder_name);
      },
      API.defaultError,
    );
  },

  getIntegrationFolderNameByUserGroup: (folderProvider, groupId, onSuccess = () => {}) => {
    API.get(
      `/groups/${groupId}/integration_folder_name/${folderProvider}`,
      (response) => {
        onSuccess(response?.data?.integration_folder_name);
      },
      API.defaultError,
    );
  },

  getIntegrationFolderByUserGroup: (folderProvider, groupId, onSuccess = () => {}) => {
    API.get(
      `/groups/${groupId}/integration_folder/${folderProvider}/`,
      (response) => {
        onSuccess(response?.data?.integration_folder);
      },
      () => {
        onSuccess({ folderProvider: null });
      },
    );
  },

  getIntegrationFoldersByUserGroup: (groupId, onSuccess = () => {}) => {
    API.get(
      `/groups/${groupId}/integration_folders/`,
      (response) => {
        onSuccess(response?.data?.integration_folders);
      },
      () => {
        onSuccess({ folderProvider: null });
      },
    );
  },

  /*
   * groupIds: an array of group IDs
   * response: an object with group IDs as keys and integration folder objects as values.
   *   Any group IDs that don't have an integration folder will not be included in the response object.
   */
  getIntegrationFoldersByUserGroups: (groupIds, onSuccess = () => {}) => {
    API.post(
      '/groups/bulk/integration_folders/',
      { group_ids: groupIds },
      (response) => {
        onSuccess(response?.data?.integration_folders);
      },
      () => {
        onSuccess({});
      },
    );
  },

  getIntegrationFolderNameByEnterprise: (folderProvider, enterpriseId, onSuccess = () => {}) => {
    API.get(
      `/enterprises/${enterpriseId}/integration_folder_name/${folderProvider}`,
      (response) => {
        onSuccess(response?.data?.integration_folder_name);
      },
      API.defaultError,
    );
  },

  getDefaultIntegrationFolderName: (templateId, onSuccess = () => {}) => {
    if (!templateId) {
      onSuccess('');
      return;
    }
    API.get(
      `/templates/${templateId}/default_integration_folder_name/`,
      (response) => {
        onSuccess(response?.data?.integration_folder_name);
      },
      API.defaultError,
    );
  },

  getDefaultIntegrationFolder: (templateId, onSuccess = () => {}) => {
    API.get(
      `/templates/${templateId}/default_integration_folder/`,
      (response) => {
        onSuccess(response?.data?.integration_folder);
      },
      API.defaultError,
    );
  },

  getDefaultNameTemplate: (orderedInputs) => {
    let defaultNameTemplate = [];
    defaultNameTemplate.push({
      id: uuidv4(),
      componentType: 'TEMPLATE_NAME',
      parameters: {},
    });
    if (orderedInputs.length > 0) {
      defaultNameTemplate.push({
        id: uuidv4(),
        componentType: 'INPUT_NAME',
        parameters: { parameterId: orderedInputs[0].id },
      });
      defaultNameTemplate.push({
        id: uuidv4(),
        componentType: 'INPUT_VALUE',
        parameters: { parameterId: orderedInputs[0].id },
      });
    }
    defaultNameTemplate.push({
      id: uuidv4(),
      componentType: 'TIME_STAMP',
      parameters: { text: Constants.DEFAULT_DATE_FORMAT },
    });
    return defaultNameTemplate;
  },

  getNameTemplatePreview: (template, orderedInputs = [], inputValues = []) => {
    let nameTemplate;
    if (template?.presentation_name_template?.components?.length) {
      // Need to give each component of the name template a temporary ID to make them easier to refer to in the UI
      nameTemplate = template.presentation_name_template.components;
    } else {
      nameTemplate = utils.getDefaultNameTemplate(orderedInputs);
    }
    const renderComponent = (component) => {
      switch (component.componentType) {
        case 'INPUT_NAME':
          for (let orderedInput of orderedInputs) {
            if (component.parameters && orderedInput.id === component.parameters.parameterId) {
              return orderedInput.name;
            }
          }
          return '[INPUT_NAME]';
        case 'INPUT_VALUE':
          for (let orderedInput of orderedInputs) {
            if (component.parameters && orderedInput.id === component.parameters.parameterId) {
              if (orderedInput.name in inputValues) {
                const inputValue = inputValues[orderedInput.name]?.value;
                if (typeof inputValue == 'string') {
                  return inputValue;
                } else if (inputValue instanceof Array) {
                  return `[${inputValue.toString()}]`;
                }
              }
              return '{inputValue}';
            }
          }
          return '[INPUT_VALUE]';
        case 'PLAIN_TEXT':
          return component.parameters.text;
        case 'TEMPLATE_NAME':
          return template?.name || '';
        case 'TIME_STAMP':
          return component.parameters.text;
        default:
          break;
      }
      return '';
    };
    const componentStrings = nameTemplate.map(renderComponent);
    return componentStrings.join(' - ');
  },

  convertImageUrlToBase64: async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  hasMatikUserInputs(inputs) {
    return Array.isArray(inputs) && inputs.some((input) => input.source_type === 'matik_user');
  },
};

export default utils;
