import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Tile, Message } from 'react-bulma-components';
import Constants from '../../../Constants';
import InputsList from '../InputsList';
import SampleData from '../SampleData';
import { mapInputsStateToProps } from '../../../../redux/inputs/stateMappers';
import { mapInputDispatchToProps } from '../../../../redux/inputs/dispatchers';
import TestResult from '../../../shared/TestResult';
import ConditionalContentCondition from '../../../shared/ConditionalContentCondition';
import { ReactComponent as AddCircleFilled } from '../../../../svg/add_circle_filled.svg';
import Button from '../../../lib/Button';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

class ConditionalContentForm extends Component {
  render() {
    const queryObj = this.context.query ? JSON.parse(this.context.query) : {};
    const body = (
      <React.Fragment>
        {queryObj.conditions &&
          queryObj.conditions.map((condition, idx) => (
            <ConditionalContentCondition
              key={idx}
              condition={condition}
              conditionIndex={idx}
              formRef={this.props.formRef}
              inputs={this.context.existingInputs}
              queryObj={queryObj}
              isReadOnly={this.context.isReadOnly}
              updateQuery={this.updateQuery}
              dynamicContentArr={this.props.dynamicContentArr}
              generateEmptyClause={this.generateEmptyClause}
            />
          ))}
        <Form.Field className="ptm">
          <Form.Control>
            <Button
              onClick={(e) => this.addEmptyCondition(e, queryObj)}
              status={
                this.context.isReadOnly || Object.keys(this.context.existingInputs || {}).length === 0
                  ? 'disabled'
                  : 'default'
              }
            >
              <AddCircleFilled className="has-text-white mrs" />
              Add Condition Group
            </Button>
          </Form.Control>
        </Form.Field>
      </React.Fragment>
    );

    return (
      <Tile kind="parent" className="is-paddingless" vertical>
        <Tile kind="parent" className="is-paddingless">
          <Tile kind="child" className="is-paddingless is-shadowless query-grandparent" size={12}>
            <Message className="mtn mbl" color="message-info">
              <Message.Body>
                This dynamic content will return the dynamic content from the first condition group that returns true
              </Message.Body>
            </Message>
            <Form.Field className="box is-paddingless is-shadowless has-light-gray-border mbl pbm">
              <Form.Label className="plm ptm">Included Inputs</Form.Label>
              <InputsList
                inputsInQueryString={this.props.inputsInQueryString}
                key={this.props.selectedInputName}
                isReadOnly={this.context.isReadOnly}
                selectedInputName={this.props.selectedInputName}
                toggleCurrentInput={this.props.toggleCurrentInput}
              />
            </Form.Field>
            {body}
            <TestResult testResult={this.props.testResult} />
          </Tile>
        </Tile>
        <SampleData dynamicContentType={this.props.testResult?.dynamicContentType} testResult={this.props.testResult} />
      </Tile>
    );
  }

  generateEmptyClause = () => {
    const supported_operators = Constants.SUPPORTED_OPERATORS_BY_DATA_SOURCE.conditional;
    const inputName = Object.keys(this.context.existingInputs)[0];
    return { param: inputName, operator: supported_operators[0], operand: '' };
  };

  addEmptyCondition = (e, queryObj) => {
    e.preventDefault();
    const updatedQueryObj = Object.assign({}, queryObj);
    if (!updatedQueryObj.conditions) {
      updatedQueryObj.conditions = [];
    }
    updatedQueryObj.conditions.push({
      clauses: [this.generateEmptyClause()],
      dynamicContent: this.props.dynamicContentArr ? this.props.dynamicContentArr[0].id : 0,
    });
    this.context.onQueryObjectUpdate(updatedQueryObj);
  };

  updateQuery = (updatedQueryObj, updateInputs) => {
    this.context.onQueryObjectUpdate(updatedQueryObj);
    if (updateInputs) {
      this.updateInputs();
    }
  };

  updateInputs = () => {
    this.props.updateInputValuesAndInitFromInputs(
      this.props.entityType,
      this.props.entityId,
      this.props.inputValues,
      Object.values(this.props.inputsInQueryString),
    );
  };
}

ConditionalContentForm.contextType = DynamicContentContext;

ConditionalContentForm.propTypes = {
  dynamicContentArr: PropTypes.array,
  selectedInputName: PropTypes.string,
  inputsInQueryString: PropTypes.object,
  toggleCurrentInput: PropTypes.func,
  testResult: PropTypes.object,
  updateInputValuesAndInitFromInputs: PropTypes.func,
  inputValues: PropTypes.object,
  entityId: PropTypes.any,
  entityType: PropTypes.string,
  formRef: PropTypes.object,
};

export default connect(mapInputsStateToProps, mapInputDispatchToProps)(ConditionalContentForm);
